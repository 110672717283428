<template>
	<v-card :loading="loadingParent" max-width="800" class="mx-auto" rounded="xl">
		<v-card-title>
			<span>{{ $t('offers.requisites') }}</span>
			<v-spacer />
			<OfferNeedsUpdateForm :loading-parent="loadingParent || loading" />
		</v-card-title>
		<v-card-text>
			<v-row class="mb-2">
				<v-col>
					<v-chip :ripple="false" v-for="sector in offer.info.sectors" :key="`sector${sector.id}`" class="mx-1 my-1">
						{{ sector.name }}
					</v-chip>
				</v-col>
			</v-row>
			<p style="white-space: pre-line">{{ offer.info.qualifications }}</p>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'OfferNeeds',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		OfferNeedsUpdateForm: () => import('@/components/offers/OfferNeedsUpdateForm.vue')
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer'
		})
	}
}
</script>
